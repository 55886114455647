import { API } from 'aws-amplify';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, FormLabel, Modal, Row, Table } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uniqid from 'uniqid';
import { StatCard } from '../../components/Card';
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import { CardShipperFilter, CardShipperGroupFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { BoxStatusBadge } from '../../components/StatusBadge';
import Pagination, { BoxStatusFilter, TableClearFilter, TableKeywordSearch, WarehouseFilter } from '../../components/TableFilter';
import BoxSize from "../../data/box-size.json";
import BoxStatus from '../../data/box-status.json';
import BoxVersion from "../../data/box-version.json";
import { DELAY_TIMEOUT, PAGE_SIZE, getShipperGroupsAndShippers, handleApiError, isViewer } from '../../helpers';

const _ = require("lodash");

const updateShipmentBoxMutation = /* GraphQL */ `
  mutation UpdateShipmentBox(
    $input: UpdateShipmentBoxInput!
  ) {
    updateShipmentBox(input: $input) {
      id
    }
  }
`;

const createBoxStatusHistory = /* GraphQL */ `
mutation CreateBoxStatusHistory(
  $input: CreateBoxStatusHistoryInput!
  $condition: ModelBoxStatusHistoryConditionInput
) {
  createBoxStatusHistory(input: $input, condition: $condition) {
	id
	boxId
	userId
	shipmentId
  }
}
`;

const createShipmentBox = /* GraphQL */ `
  mutation CreateShipmentBox(
    $input: CreateShipmentBoxInput!
    $condition: ModelShipmentBoxConditionInput
  ) {
    createShipmentBox(input: $input, condition: $condition) {
      id
    }
  }
`;
const listWarehouses = /* GraphQL */ `
  query ListWarehouses($limit: Int) {
    listWarehouses( limit: $limit) {
      items {
        id
        name
        alias
        phone
        email
      }
    }
  }
`;

const AdminBoxes = () => {

    const myUser = useSelector((state) => state.slice.USER)
    const navigate = useNavigate()

    const [boxStatusCount, setBoxStatusCount] = useState({})
    const [boxData, setBoxData] = useState({ status: 'AVAILABLE' });
    const [boxlist, setBoxlist] = useState({});
    const [currBox, setCurrBox] = useState({});
    const [boxStatus, setBoxstatus] = useState('');
    const [keyword, setKeyword] = useState('')
    const [totalBoxCount, setTotalBoxCount] = useState(null)
    const [spinner, showSpinner] = useState(false);

    const [shippers, setShippers] = useState([])
    const [shipper, setShipper] = useState()
    const [cardShipper, setCardShipper] = useState()

    const [shipperGroups, setShipperGroups] = useState([]);
    const [shipperGroup, setShipperGroup] = useState();
    const [cardShipperGroup, setCardShipperGroup] = useState();

    const [facets, setFacets] = useState({})

    const [errors, setErrors] = useState();

    const [token, setToken] = useState(null);
    const [nextToken, setNextToken] = useState(null);
    const [tokens, setTokens] = useState([]);

    const [boxModal, showBoxModal] = useState(false)
    const [releaseBoxModal, setReleaseBoxModal] = useState(false)
    const [createBoxModal, showCreateBoxModal] = useState(false)

    const [boxStatusHistoryData, setBoxStatusHistoryData] = useState();
    const [statusDate, setStatusDate] = useState(new Date());
    const [warehouseList, setWarehouseList] = useState([])
    const [selectedWarehoue, setSelectedWarehoue] = useState({})


    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/green-phox",
        })
        
    }, [])

    useEffect(() => {
        showSpinner(true)
        const delay = setTimeout(() => { getBoxList(); }, DELAY_TIMEOUT)
        return () => clearTimeout(delay)
    }, [nextToken, boxStatus, shipperGroup, shipper, selectedWarehoue, keyword])

    useEffect(() => {
        if (shipperGroup?._source?.id || boxData?.shipperGroupId || currBox?.shipperGroupId) {
            getShipperGroupsAndShippers(setShipperGroups, setShippers, shipperGroup?._source?.id || boxData?.shipperGroupId || currBox?.shipperGroupId);
        } else {
            getShipperGroupsAndShippers(setShipperGroups, setShippers);
        }
    }, [boxData?.shipperGroupId, shipperGroup?._source?.id, currBox?.shipperGroupId])

    useEffect(() => {
        getWarehouseList()
    }, [])

    const getBoxList = async () => {
        showSpinner(true)

        try {
            const apiName = 'api';
            const path = `/search/shipment-box?size=${PAGE_SIZE}`;

            let init = {
                body: {
                    "track_total_hits": true,
                    aggs: {
                        shipper_group: {
                            terms: {
                                "field": "shipperGroup.name.keyword",
                                "size": 1000
                            }
                        },
                        shipper: {
                            terms: {
                                "field": "shipper.name.keyword",
                                "size": 1000
                            }
                        },
                        status: {
                            terms: {
                                "field": "status.keyword",
                                "size": 1000
                            }
                        }
                    },
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "createdAt": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            "must": [],
                            "filter": []
                        }
                    }
                }
            };

            if (keyword) {
                let fields = [
                    "name",
                ]
                if (+keyword) fields.push("number")
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword?.trim(),
                            "fields": fields
                        }
                    }
                )

            }
            if (boxStatus) init.body.query.bool.filter.push({ "term": { "status.keyword": boxStatus } })
            if (shipperGroup) init.body.query.bool.filter.push({ "term": { "shipperGroup.name.keyword": shipperGroup } })
            if (shipper) init.body.query.bool.filter.push({ "term": { "shipper.name.keyword": shipper } })
            if (selectedWarehoue?.id) init.body.query.bool.filter.push({ "term": { "warehouseId.keyword": selectedWarehoue?.id } })

            const data = await API.post(apiName, path, init);
            const boxElasticData = data?.hits.hits.map((item) => item._source)
            let total = data?.hits?.total.value;
            let statusCount = _.groupBy(boxElasticData, 'status');

            let withOutShipperId = 0;
            boxElasticData?.forEach(item => {
                if (!item.shipperId) {
                    withOutShipperId++;
                }
            });
            setTotalBoxCount(data?.hits?.total.value);
            setBoxStatusCount({ ...boxStatusCount, statusCount, total, withOutShipperId });
            setBoxlist(boxElasticData);
            setFacets(data?.aggregations);
            showSpinner(false)
        } catch (error) {
            showSpinner(false)
            handleApiError(error)
        }
    }

    const getWarehouseList = async () => {
        try {
            await API.graphql({ query: listWarehouses, variables: { limit: 100 } }).then((response) => {
                setWarehouseList(response?.data?.listWarehouses?.items);
            })

        } catch (error) {
            handleApiError(error);
        }
    }

    function handleNext() {
        showSpinner(true);
        setNextToken(token);
        setToken(null);
    }

    function handlePrev() {
        showSpinner(true);
        let temp = structuredClone(tokens);
        temp.pop();
        temp.pop();
        setTokens(temp);
        setNextToken(temp.at(-1));
    }

    const UpdateBoxStatus = () => {
        if ((!currBox?.shipperGroupId || !currBox?.shipperId) && !["RETIRED", "DAMAGED"].includes(currBox?.status)) {
            toast.error('Shipper Group & Shipper is required')
        } else {
            const inputDate = moment(statusDate).format('YYYY-MM-DD HH:mm')
            const tzDateTime = moment(inputDate).unix()

            if (!currBox?.status) {
                toast.error('Box status is Required')
            } else {
                showSpinner(true)
                setReleaseBoxModal(false)
                let boxInput = {
                    id: currBox?.id,
                    status: currBox?.status,
                    shipperGroupId: currBox?.shipperGroupId,
                    shipperId: currBox?.shipperId,
                    warehouseId: currBox?.warehouseId,
                    name: currBox?.name
                }

                if (currBox?.warehouseId) {
                    boxInput.userId = null
                }

                if (currBox?.status === "RETIRED") {
                    boxInput.retiredAt = moment().unix();
                }

                if (["RETIRED", "DAMAGED"].includes(currBox?.status)) {
                    boxInput.shipperGroupId = null
                    boxInput.shipperId = null
                    boxInput.carrierId = null
                }

                let statusArray = ['RETURNED', 'AVAILABLE']
                if (statusArray?.includes(currBox?.status)) {
                    boxInput.userId = null;
                    boxInput.carrierId = null;
                    boxInput.shipmentId = null;
                }

                let historyInput = {
                    boxId: currBox?.id,
                    shipmentId: currBox?.shipmentId,
                    userId: myUser?.id,
                    status: currBox?.status,
                    createdTime: tzDateTime,
                    description: currBox?.description
                }

                let task = []
                task.push(API.graphql({ query: updateShipmentBoxMutation, variables: { input: boxInput } }))

                let statusArray2 = ['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'];
                statusArray2?.includes(currBox?.status) && currBox?.shipmentId && task.push(API.graphql({ query: createBoxStatusHistory, variables: { input: historyInput } }))

                Promise.all(task).then((response) => {
                    setTimeout(() => {
                        toast.success('Box status has been updated')
                        setBoxStatusHistoryData()
                        getBoxList();
                        showSpinner(false);
                        showBoxModal(false)
                    }, 1000)
                }).catch((error) => {
                    toast.error('Opps,something went wrong');
                    console.error(error);
                    showSpinner(false);
                    showBoxModal(false)
                })
            }

        }

    }
    const handleNumberChange = async (e) => setBoxData((prevState) => ({ ...prevState, [e.target.name]: e.target.value.toUpperCase() }));
    const isFormValid = async () => {
        showSpinner(true)
        const error = {};
        if (!boxData?.name) error['number'] = 'Number is required'
        if (boxData?.name?.trim()?.length === 0) error['number'] = 'Number can not be empty'
        if (!boxData?.type) error['type'] = 'Box Type is required'
        if (boxData?.type !== 'Ember Cube' && !boxData?.size) error['size'] = 'Box Size is required'
        if (boxData?.name?.trim().length) {
            let init = {
                body: {
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "createdTime": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                {
                                    multi_match: {
                                        "query": boxData?.name,
                                        "fields": ["name"]
                                    }
                                }
                            ]
                        }
                    }
                }
            }

            const { hits } = await API.post("api", "/search/shipment-box", init)
            if (hits?.hits?.length > 0) {
                error['number'] = 'Box number is already in use. Please choose another number.'
            }
        }
        setErrors(error)
        showSpinner(false)
        return Object.keys(error).length === 0;
    };
    const CreateShipmentBox = async () => {
        let isvalid = await isFormValid()
        if (isvalid) {
            showSpinner(true)
            boxData.status = 'AVAILABLE'
            boxData.id = uniqid();
            if (boxData?.shipperId) {
                boxData.assignedAt = moment().unix()
            }

            API.graphql({ query: createShipmentBox, variables: { input: boxData } })
                .then((response) => {
                    setTimeout(() => {
                        getBoxList()
                        setBoxData({})
                        setCardShipper(null)
                        setCardShipperGroup(null)
                        toast.success('Box has been created')
                        showCreateBoxModal(false)
                        showSpinner(false)
                    }, 1000)
                }).catch((error) => {
                    console.error(error);
                    toast.error('Opss, Something went wrong')
                    showSpinner(false)
                })
        }
    }
    const getShipperGroupFun = (data) => {
        setCardShipperGroup(data)
        setBoxData({ ...boxData, shipperGroupId: data?.value })
    }
    const getShipperFun = (data) => {
        setCardShipper(data)
        setBoxData({ ...boxData, shipperId: data?.value })
    }
    const goToBoxDetails = async (boxId) => {
        showSpinner(true)
        try {
            const apiName = 'api';
            const path = `/search/shipment?size=${PAGE_SIZE}`;
            const init = {
                body: {
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        },
                        {
                            "createdTime": {
                                "order": "desc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                { "match": { boxId } }
                            ]
                        }
                    }

                }
            };

            const { hits } = await API.post(apiName, path, init);
            if (hits.hits.length > 0) {
                let shipmentId = hits?.hits[0]?._id
                !isViewer(myUser.role) && navigate(`/box/details/${shipmentId}`)
            } else {
                toast.error("No shipment record found for this box.")
            }

            showSpinner(false)
        } catch (error) {
            showSpinner(false)
            console.error("shipment by box Error:", error);
            toast.error("something went wrong!")
        }
    }
    const clearFilters = () => {
        setKeyword(null);
        setShipperGroup(null);
        setShipper(null);
        setBoxstatus(null);
        setSelectedWarehoue(null)
    }
    const openReleaseBoxModal = () => {
        setReleaseBoxModal(true);
        showBoxModal(false)
    }

    const selectWarehoue = (warehouseId) => {
        let warehouse = warehouseList.filter((item) => item?.id === warehouseId)
        let description = warehouse.length > 0 ? `Admin has assign this box to warehouse (${warehouse[0].name})` : ""
        setCurrBox((prev) => ({ ...prev, warehouseId, description }))
    }

    return (

        <>
            <PageHeader name='Box Management' pageTitle={"GreenPhox Box List"} shipperLabel={shipperGroup}>
                <Row>
                    <Col>
                        <Button className='btn btn-dark btn-md' onClick={() => showCreateBoxModal(true)} >Create Box</Button>
                    </Col>
                </Row>
            </PageHeader>
            <Container fluid>
                <Row>
                    <StatCard title='Total Boxes' value={boxStatusCount?.total ?? 0} icon='box' />
                    <StatCard title='Available in Inventory' value={boxStatusCount?.withOutShipperId ?? 0} icon='check-circle' />
                    <StatCard title='Assigned' value={(!isNaN(boxStatusCount.total - boxStatusCount?.withOutShipperId) && (boxStatusCount.total - boxStatusCount?.withOutShipperId)) || 0} icon='repeat' />
                    <StatCard title='Exceptions' value={boxStatusCount?.statusCount?.DAMAGED?.length + boxStatusCount?.statusCount?.RETIRED?.length || boxStatusCount?.statusCount?.RETIRED?.length || boxStatusCount?.statusCount?.DAMAGED?.length || 0} icon='alert-triangle' />
                </Row>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            <ElasticFacetFilter name='Shipper Group' item={shipperGroup} facets={facets} facetName='shipper_group' onChange={setShipperGroup} setShipper={setShipper} />
                            <ElasticFacetFilter name='Shipper' item={shipper} facets={facets} facetName='shipper' onChange={setShipper} />
                            <WarehouseFilter warehouseList={warehouseList} selectedWarehoue={selectedWarehoue} onChange={setSelectedWarehoue} />
                            <BoxStatusFilter value={boxStatus} onChange={setBoxstatus} />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner} >
                        <Table responsive size='sm' className='m-0'>
                            <thead>
                                <tr >
                                    <th className='text-center'>Box Number</th>
                                    <th className='text-center'>Shipper</th>
                                    <th className='text-center'>Courier / Driver</th>
                                    <th className='text-center'>Last Update </th>
                                    <th className='text-center'>Warehouse </th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    boxlist.length > 0 && boxlist.sort((a, b) => b?.updatedAt.localeCompare(a?.updatedAt, undefined, { sensitivity: 'accent' })).map((item, index) => {
                                        return <tr key={index} onClick={() => goToBoxDetails(item?.id)} style={{ cursor: "pointer" }} >
                                            <td className='text-center'> #{item?.number || item?.name} </td>
                                            <td className='text-center' >
                                                {item?.shipper ? item?.shipper?.name : '-'}
                                                <div className='text-muted'>
                                                    {item?.shipperGroup?.name}
                                                </div>
                                            </td>
                                            <td className='text-center' >
                                                {item?.carrierId ?
                                                    <>
                                                        {item?.carrier?.name}
                                                        <div className='text-muted'>
                                                            {item?.userId ? item?.user?.name : 'Unassigned'}
                                                        </div>
                                                    </>

                                                    : '-'
                                                }
                                            </td>
                                            <td className='text-center'>
                                                {moment(item?.updatedAt).format('h:mm a z')}
                                                <div className='text-muted'>
                                                    {moment(item?.updatedAt).format('MMM D, YYYY')}
                                                </div>
                                            </td>
                                            <td className='text-center'> {item?.warehouse?.name || '-'}  </td>
                                            <td className='text-center'> <BoxStatusBadge status={item?.status} /> </td>
                                            <td className='text-center' onClick={(e) => e.stopPropagation()} >
                                                <Button className='btn btn-sm btn-light' title='click to release the box' onClick={() => { setCurrBox(item); showBoxModal(true) }}>Update Box</Button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <Pagination handleNext={handleNext} handlePrev={handlePrev} length={tokens?.length} token={token} total={boxlist?.length} colSpan={6} />
                        </Table>
                    </Spinner>
                </Card>
            </Container >
            <Modal Modal show={boxModal} onHide={() => showBoxModal(false)}>
                <Modal.Header>
                    <h4>Update Box </h4>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <FormLabel required={true}>Box Number</FormLabel>
                        <Form.Control type='text' name='name' placeholder='e.g. 00122' value={currBox?.name || currBox?.number} onChange={(e) => setCurrBox((prev) => ({ ...prev, name: e.target.value.toUpperCase() }))} disabled />
                    </Form.Group>
                    {
                        !["RETIRED", "DAMAGED"].includes(currBox?.status) &&
                        <Row>
                            <Col lg={6} className='mb-3'>
                                <Form.Group>
                                    <FormLabel >Shipper Group</FormLabel>
                                    <Form.Select className='phox-form-select' value={currBox?.shipperGroupId} onChange={(e) => { setCurrBox((prev) => ({ ...prev, shipperGroupId: e.target.value })); setBoxData({ ...boxData, shipperGroupId: e.target.value }) }} >
                                        <option value=''>Select Shipper Group</option>
                                        {
                                            shipperGroups?.map((shipperGroup, index) => {
                                                return <option key={index} value={shipperGroup?._source?.id}>{shipperGroup?._source?.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6} className='mb-3'>
                                <Form.Group>
                                    <FormLabel >Shipper</FormLabel>
                                    <Form.Select className='phox-form-select' value={currBox?.shipperId} onChange={(e) => setCurrBox({ ...currBox, shipperId: e.target.value })} >
                                        <option value=''>Select Shipper</option>
                                        {
                                            shippers?.map((shipper, index) => {
                                                return <option key={index} value={shipper?._source?.id}>{shipper?._source?.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    <FormGroup>
                        <FormLabel> Box Status</FormLabel>
                        <Form.Select value={currBox?.status} onChange={(e) => setCurrBox((prev) => ({ ...prev, status: e.target.value, warehouseId: null, description: "" }))}>
                            <option value={''}>Select status</option>
                            {Object.keys(BoxStatus).map((item, index) => <option key={index} value={item}>{BoxStatus[item]}</option>)}
                        </Form.Select>
                    </FormGroup>

                    <FormGroup className='mt-3'>
                        <FormLabel> Warehouse</FormLabel>
                        <Form.Select key={currBox?.status} value={currBox?.warehouseId} onChange={(e) => selectWarehoue(e.target.value)}>
                            <option value={''}>Select status</option>
                            {warehouseList.map((item, index) => <option key={index} value={item?.id}>{item?.name}</option>)}
                        </Form.Select>
                    </FormGroup>

                    <FormGroup className='my-3' >
                        <FormLabel> Description</FormLabel>
                        <textarea key={currBox?.status} className='form-control' value={currBox?.description} rows={2} onChange={(e) => setCurrBox((prev) => ({ ...prev, description: e.target.value }))} />
                    </FormGroup>

                    {
                        ['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'].includes(boxStatusHistoryData?.status) && <>
                            <FormLabel required>
                                {boxStatusHistoryData?.status === 'IN_USE' && 'Actual In Use Time'}
                                {boxStatusHistoryData?.status === 'OUT_FOR_DELIVERY' && 'Actual Pickup Time'}
                                {boxStatusHistoryData?.status === 'DELIVERED' && 'Actual Delivery Time'}
                                {boxStatusHistoryData?.status === 'OUT_FOR_RETURN' && 'Out for Return Time'}
                                {boxStatusHistoryData?.status === 'RETURNED' && 'Returned Time'}
                            </FormLabel>
                            <div className='mb-3'>
                                <Flatpickr className='form-control d-block' name='statusDate'
                                    value={statusDate}
                                    options={{ enableTime: true, dateFormat: 'M d, Y h:i K', static: true }}
                                    onChange={(dates) => setStatusDate(new Date(dates[0]))}
                                />
                            </div>
                        </>
                    }


                </Modal.Body>
                <Modal.Footer className='display-flex-start'>
                    <Spinner display={spinner}>
                        <Button className='btn btn-dark' onClick={() => {
                            currBox?.status === "RETIRED" ? openReleaseBoxModal() : UpdateBoxStatus()
                        }}>Update</Button>
                        <Button className='btn btn-light' onClick={() => { setBoxStatusHistoryData(); showBoxModal(false) }} >Cancel</Button>
                    </Spinner>
                </Modal.Footer>
            </Modal>

            <Modal show={releaseBoxModal} onHide={() => setReleaseBoxModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered  >
                <Modal.Body>
                    <div>
                        <div className='d-flex justify-content-center' >
                            <i className='fe fe-alert-circle text-warning' style={{ fontSize: "80px" }}></i>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h4 className='text-muted'>Are you sure you want to retire PhoxBox {currBox?.name || currBox?.number}?</h4>
                        </div>
                        <div className='d-flex justify-content-end mt-4'>
                            <button className='btn btn-md btn-light me-2'
                                onClick={() => { setReleaseBoxModal(false) }}> No </button>
                            <button className='btn btn-md btn-danger'
                                onClick={() => UpdateBoxStatus()}> Yes </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={createBoxModal} onHide={() => { showCreateBoxModal(false); setCardShipper(null); setCardShipperGroup(null); setBoxData({}); setErrors(); }}>
                <Modal.Header>
                    <h4> Create Box </h4>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col lg={12} className='mb-2'>
                            <Form.Group className='mt-2'>
                                <FormLabel >Box Type</FormLabel>
                                <Form.Select className='phox-form-select' style={{ fontSize: '13.5px', color: '#868686', height: '160x', fontWeight: "1px" }} value={boxData?.type} onChange={(e) => setBoxData({ ...boxData, type: e.target.value })} isInvalid={!!errors?.type}  >
                                    <option value='' >Select Box Type</option>
                                    {
                                        Object.keys(BoxVersion).map((key, index) => {
                                            return <option key={index} value={key}> {BoxVersion[key]} </option>
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors?.type}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {boxData?.type === "Ember Cube" && (
                            <Col lg={12} className='mb-2'>
                                <Form.Group className='mt-2'>
                                    <FormLabel >Serial Number</FormLabel>
                                    <Form.Control type='text' name='serialNumber' placeholder='e.g. 1680-140-080-695-A080004' value={boxData?.serialNumber || ''} onChange={handleNumberChange} isInvalid={!!errors?.serialNumber} />
                                    <Form.Control.Feedback type='invalid'>{errors?.serialNumber}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        )}
                        <Col lg={6} className='mb-2'>
                            <Form.Group className='mt-2'>
                                <FormLabel required={true}>Box Number</FormLabel>
                                <Form.Control type='text' name='name' placeholder='e.g. 00122' value={boxData?.name || ''} onChange={handleNumberChange} isInvalid={!!errors?.number} />
                                <Form.Control.Feedback type='invalid'>{errors?.number}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {boxData?.type !== "Ember Cube" && (
                            <Col lg={6} className='mb-4'>
                                <Form.Group className='mt-2'>
                                    <FormLabel required={true}>Box Size</FormLabel>
                                    <Form.Select className='phox-form-select' style={{ fontSize: '13.5px', color: '#868686', height: '160x', fontWeight: "1px" }} value={boxData?.size} onChange={(e) => setBoxData({ ...boxData, size: e.target.value })} isInvalid={!!errors?.size}  >
                                        <option value=''> Select Box Size</option>
                                        {
                                            Object.keys(BoxSize).map((key, index) => {
                                                return <option key={index} value={key}> {BoxSize[key]} </option>
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>{errors?.size}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        )}
                        <Col lg={6} className='mb-4'>
                            <Form.Group>
                                <FormLabel >Shipper Group</FormLabel>
                                <CardShipperGroupFilter value={cardShipperGroup} onChange={getShipperGroupFun} inCard={true} />
                                {/* <Form.Select className='phox-form-select' value={boxData?.shipperGroupId} onChange={(e) => setBoxData({ ...boxData, shipperGroupId: e.target.value })} >
                                    <option value=''>Select Shipper Group</option>
                                    {
                                        shipperGroups?.map((shipperGroup, index) => <option key={index} value={shipperGroup?._source.id}>{shipperGroup?._source?.name}</option>)
                                    }
                                </Form.Select> */}
                            </Form.Group>
                        </Col>
                        <Col lg={6} className='mb-4'>
                            <Form.Group>
                                <FormLabel >Shipper</FormLabel>
                                <CardShipperFilter value={cardShipper} shipperGroup={cardShipperGroup} onChange={getShipperFun} inCard={true} />
                                {/* <Form.Select className='phox-form-select' value={boxData?.shipperId} onChange={(e) => setBoxData({ ...boxData, shipperId: e.target.value })} >
                                    <option value=''>Select Shipper</option>
                                    {
                                        shippers?.map((shipper, index) => <option key={index} value={shipper?._source?.id}>{shipper?._source?.name}</option>)
                                    }
                                </Form.Select> */}
                            </Form.Group>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className='display-flex-start'>
                    <Spinner display={spinner}>
                        <Button className='btn btn-dark' onClick={() => CreateShipmentBox()}>Create</Button>
                        <Button className='btn btn-light' onClick={() => { setBoxData({}); setErrors(); showCreateBoxModal(false); setCardShipper(null); setCardShipperGroup(null) }} >Cancel</Button>
                    </Spinner>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default AdminBoxes