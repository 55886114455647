/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from '@aws-amplify/ui-react-geo';
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import awsConfig from "../../aws-exports";
import { CarrierFilter, DateFilter, DriverFilter, ShipperFilter, ShipperGroupFilter, StatusFilter } from '../../components/EntityFilter';
import { DriverMarker, ShipmentMarker, ShipperMarker } from '../../components/MapMarker';
import Spinner from '../../components/Spinner';
import { MapToggleButton, TableClearFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_TITLE } from '../../helpers';

const onShipmentUpdate = /* GraphQL */ `
  subscription ShipmentSubscription ($filter: ModelSubscriptionShipmentFilterInput) {
    onUpdateShipment(filter: $filter) {
      id
	  driverId
      status
	}
  }
`;

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
      id
	  routeSequence
      routeDistance
      routeDuration
	}
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const getUserLocationQuery = /* GraphQL */ `
  query GetUserLocation($id: ID!) {
    getUserLocation(id: $id) {
		id
		latitude
		longitude
    }
  }
`;

const usersByCarrierId = /* GraphQL */ `
  query UsersByCarrierId (
    $carrierId: ID!
    $nextToken: String
  ) {
    carrierUsersByCarrierId (
      carrierId: $carrierId
      nextToken: $nextToken
    ) {
      items {
      user {
        location {
          latitude
          longitude
          updatedAt
        }
        name
        id
      }
    }
    }
  }
`;

let pageSize=10000;


const LiveTracker = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/map",
		})
		document.title = `Live Tracker ${PAGE_TITLE}`;
	}, [])


	const mapRef = useRef();
	const mapState = { latitude: 37.7061332, longitude: -101.4802194, zoom: 4 }

	const [spinner, showSpinner] = useState(false);
	const [shipments, setShipments] = useState([])

	const [shippers, setShippers] = useState({})
	const [drivers, setDrivers] = useState({})

	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [driver, setDriver] = useState()
	const [status, setStatus] = useState()
	const [mapStyle, setMapStyle] = useState(awsConfig.geo.amazon_location_service.maps.default);

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz('America/New_York').startOf('day').unix(),
		toDate: moment().tz('America/Los_Angeles').endOf('day').unix()
	});

	useEffect(() => {
		// API.graphql({ query: onShipmentUpdate }).subscribe({
		// 	next: (response) => {
		// 		try {
		// 			const shipment = response?.value?.data?.onUpdateShipment;
		// 			console.log('shipment update', shipment)
		// 			if (shipment) {
		// 				let myShipments = [...shipments];
		// 				const index = myShipments?.findIndex(x => x.id === shipment?.id);
		// 				if (index >= 0) {

		// 					if (shipment.status) {
		// 						console.log('update shipment status', index, shipment)
		// 						myShipments[index].status = shipment.status;
		// 					}

		// 					setShipments(myShipments);
		// 				}
		// 			}
		// 		} catch (error) {
		// 			console.error('An error occurred during shipment update:', error);
		// 		}
		// 	},
		// 	error: (error) => console.error('onShipmentUpdate', error),
		// });

		// API.graphql({ query: onUserLocationUpdate }).subscribe({
		// 	next: (response) => {
		// 		try {
		// 			const driver = response?.value?.data?.onUpdateUserLocation;
		// 			// console.log('driver update', driver)

		// 			if (driver?.latitude && driver?.longitude && driver?.id) {
		// 				setDrivers(prevDrivers => {
		// 					const updatedDrivers = [...prevDrivers];
		// 					const index = updatedDrivers.findIndex(x => x.id === driver.id);
		// 					if (index >= 0) updatedDrivers[index] = { ...updatedDrivers[index], location: { latitude: driver.latitude, longitude: driver.longitude } }
		// 					return updatedDrivers;
		// 				});
		// 			}
		// 		} catch (error) {
		// 			console.error('An error occurred during user update:', error);
		// 		}
		// 	},
		// 	error: (error) => {
		// 		console.error('Subscription error:', error);
		// 	}
		// });

		API.graphql({ query: onShipmentUpdate }).subscribe({
			next: (response) => {
				const shipment = response?.value?.data?.onUpdateShipment;
				if (shipments[shipment.id]) {
					shipments[shipment.id].status = shipment.status
					setShipments(shipments);
				}
			},
			error: (error) => console.error('onShipmentUpdate', error),
		});

		API.graphql({ query: onShipmentRouteUpdate }).subscribe({
			next: (response) => {
				const route = response?.value?.data?.onUpdateShipmentRoute;
				if (shipments[route.id]) {
					shipments[route.id].route = route;
					setShipments(shipments);
				}
			},
			error: (error) => console.error('onShipmentRouteUpdate', error),
		});

		API.graphql({ query: onUserLocationUpdate }).subscribe({
			next: (response) => {
				const driver = response?.value?.data?.onUpdateUserLocation;
				if (drivers[driver.id]) {
					drivers[driver.id] = driver;
					// setDrivers(drivers);
				}
			},
			error: (error) => console.error('onUserLocationUpdate', error)
		});
	}, []);

	useEffect(() => {
		setDrivers([])
		const delay = setTimeout(() => getShipment(), DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [shipperGroup, shipper, carrier, driver, status, dateFilters]);

	useEffect(() => {
		setShipper(null)
		setCarrier(null)
	}, [shipperGroup])
	useEffect(() => {
		setCarrier(null)
	}, [shipper])

	useEffect(() => {
		if (carrier?.value) getDrivers(carrier?.value)
	}, [carrier])


	async function getShipment() {
		let loader = toast.loading("Loading...")
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${0}&from=${0}`;
			let init = {
				body: {
					query: {
						bool: {
							must: [{
								range: {
									"expectedDeliveryTime": {
										"gte": dateFilters?.fromDate,
										"lte": dateFilters?.toDate
									}
								}
							}
							]
						}
					}
				}
			}

			if (shipper) init.body.query.bool.must.push({ match: { "shipper.name.keyword": shipper?.label } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroup.name.keyword": shipperGroup?.label } })
			if (carrier) init.body.query.bool.must.push({ match: { "carrier.name.keyword": carrier?.label } })
			if (status) init.body.query.bool.must.push({ match: { "status": status } })
			if (driver) init.body.query.bool.must.push({ match: { "driverId": driver?.value } })

			let data = await API.post(apiName, path, init);
			let page = 0;
			let apiArgs = []
			while (page < Math.ceil(data.hits.total.value / pageSize)) {
				const path = `/search/shipment?size=${pageSize}&from=${page * pageSize}`;
				apiArgs.push({ apiName, path, init });
				page++;
			}
			let promises = apiArgs.map(call => API.post(call.apiName, call.path, call.init));
			Promise.all(promises).then(res => {
				let items = []
				for (let item of res) {
					const sourceData = item?.hits?.hits?.map((item) => item?._source)
					items.push(...sourceData)
				}
				let drivers = {}
				let shippers = {}
				let shipments = {}
				for (var item of items) {
					shipments[item.id] = item;
					if (item?.driverId) drivers[item.driverId] = item.driver
					if (item?.shipper?.id) shippers[item?.shipper.id] = item.shipper
				}

				setShippers(shippers);
				// setDrivers(drivers);
				setShipments(shipments);
			})
		} catch (error) {
			console.error(error);
		} finally { toast.dismiss(loader) }
	}

	async function getDrivers(carrierId) {
		try {
			let res = await API.graphql({ query: usersByCarrierId, variables: { carrierId } })
			const carrierDrivers = res?.data?.carrierUsersByCarrierId?.items;
			let data = {}

			for (let item of carrierDrivers) {
				data[item.user.id] = item.user
			}
			setDrivers(data)
		} catch (error) {
			console.error(error)
		}
	}

	const clearFilters = () => {
		setStatus(null);
		setShipperGroup(null);
		setShipper(null);
		setDriver(null);
		setCarrier(null);
	}
	return (
		<>
			<Spinner display={spinner}>
				<MapView ref={mapRef} initialViewState={mapState} mapStyle={mapStyle}>
					{
						Object.keys(shipments).map((id, index) => <ShipmentMarker key={index} shipment={shipments[id]} showHyperLink={true} />)
					}
					{
						Object.keys(shippers).map((id, index) => <ShipperMarker key={index} shipper={shippers[id]} />)
					}
					{
						Object.keys(drivers).map((id, index) => <DriverMarker key={index} driver={drivers[id]} />)
					}

				</MapView>

				<div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 2 }} className='bg-white px-5 py-2'>
					<Card.Body>
						<Row>
							<DateFilter onChange={setDateFilters} />
							<StatusFilter value={status} onChange={setStatus} />
							<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} />
							{shipperGroup && <ShipperFilter value={shipper} shipperGroup={shipperGroup} onChange={setShipper} />}
							<CarrierFilter value={carrier} onChange={setCarrier} />
							{carrier && <DriverFilter value={driver} carrier={carrier} onChange={setDriver} />}
							<TableClearFilter onClick={clearFilters} styleName={"mt-4"} />
							<Spinner display={spinner} />
						</Row>
					</Card.Body>
				</div>
				<div className="" style={{ position: 'fixed', top: '90vh', right: '25px', zIndex: 2 }}>
					<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
				</div>

			</Spinner >
		</>
	)
}

export default LiveTracker;