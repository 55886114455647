/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, ShipperFilter, ShipperGroupFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableClearFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, getTZ, handleApiError, toLocalDate, toLocalTime } from '../../helpers';
import { DoughnutChart } from '../../components/Chart';
import CardHeader from 'react-bootstrap/esm/CardHeader';

const RatingDashboard = () => {

	const navigate = useNavigate();

	let intialNpsRating = [
		{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
		{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
		{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
	]
	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate, setRestDate] = useState(0);
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingCount, setRatingCount] = useState(0)
	const [ratingAverage, setRatingAverage] = useState(0)
	const [responseAverage, setResponseAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [facets, setFacets] = useState({})
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");
	const [starRating, setStarRating] = useState([]);
	const [totalStarRatingCount, setTotalStarRatingCount] = useState(0);
	const [npsRating, setNpsRating] = useState(intialNpsRating)

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone)?.subtract('days').startOf('day').unix(),
		toDate: moment().tz(timeZone)?.endOf('day').unix()
	});


	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/feedback",
		})
	}, [])
	useEffect(() => {
		const getTimeZone = async () => {
			const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
			if (timez) {
				setTimeZone(timez?.id);
				setTz(timez?.alias);
			} else {
				setTimeZone("America/New_York")
				setTz("EST")
			}
		};
		if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
		if (!shipperGroup || !shipper || !carrier) {
			setTimeZone("America/New_York");
			setTz("EST");
		}
	}, [shipperGroup?.value, shipper?.value, carrier?.value]);

	useEffect(() => {
		const delay = setTimeout(() => { setPageNumber(0); getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, shipperGroup, shipper, carrier])

	useEffect(() => {
		getElasticRatings();
	}, [pageNumber]);

	function caluclateAverageResponseRate(totalShipments, totalNPSCount, totalRatingCount) {
		let avg_response_rate;
		if (totalShipments != null && totalRatingCount > totalNPSCount) {
			avg_response_rate = (totalRatingCount / totalShipments) * 100;
		}
		else if (totalNPSCount != null) {
			avg_response_rate = (totalNPSCount / totalShipments) * 100;
		} else {
			avg_response_rate = 0
		}
		return avg_response_rate?.toFixed(2)
	}

	const getElasticRatings = async () => {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					"aggs": {
						"shipper_group": {
							"terms": {
								"field": "shipperGroup.name.keyword",
							},
							"aggs": {
								"total_shipments_count": {
									"value_count": {
										"field": "shipperGroup.name.keyword"
									}
								}
							}
						},
					},
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipper) init.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })

			let feedbackCountData = await API.post(apiName, path, init);
			let totalShipmentsCount = feedbackCountData?.hits?.total?.value;

			const shipmentInit = {
				body: {
					"aggs": {
						"nps_stats": {
							"scripted_metric": {
								"init_script": "state.promoters = 0; state.passives = 0; state.detractors = 0; state.total = 0;",
								"map_script": `
									if (doc.containsKey('ratings.items.nps') && doc['ratings.items.nps'].size() > 0) {
										def nps = doc['ratings.items.nps'].value;
										state.total += 1;
										
										if (nps > 8) {
										state.promoters += 1;
										} else if (nps >= 7 && nps <= 8) {
										state.passives += 1;
										} else {
										state.detractors += 1;
										}
									}
								`,
								"combine_script": "return state;",
								"reduce_script": `
								 int promoters = 0;
								int passives = 0;
								int detractors = 0;
								int total = 0;

								for (s in states) {
									promoters += s.promoters;
									passives += s.passives;
									detractors += s.detractors;
									total += s.total;
								}

								if (total > 0) {
									double promotersPercentage = (promoters / (double) total) * 100;
									double passivesPercentage = (passives / (double) total) * 100;
									double detractorsPercentage = (detractors / (double) total) * 100;

									double npsScore = Math.ceil(((promoters - detractors) / (double) total) * 100);

									return [
									"promoters": promoters,
									"promotersPercentage": promotersPercentage,
									"passives": passives,
									"passivesPercentage": passivesPercentage,
									"detractors": detractors,
									"detractorsPercentage": detractorsPercentage,
									"npsScore": npsScore,
									"total": total
									];
								} else {
									return [
									"promoters": promoters,
									"promotersPercentage": 0,
									"passives": passives,
									"passivesPercentage": 0,
									"detractors": detractors,
									"detractorsPercentage": 0,
									"npsScore": 0,
									"total": total
									];
								}
								`
							}
						},
						"avg_rating": {
							"avg": {
								"field": "ratings.items.rating"
							}
						},
						"total_ratings_count": {
							"value_count": {
								"field": "ratings.items.rating"
							}
						},
						"ratings_by_star": {
							"terms": {
								"field": "ratings.items.rating",
								"size": 5,
								"order": { "_key": "asc" },
								"min_doc_count": 0
							},
							"aggs": {
								"rating_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								}
							}

						},
						"shipper_group": {
							"terms": {
								"field": "shipperGroup.name.keyword",
							},
							"aggs": {
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"avg_nps": {
									"scripted_metric": {
										"init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
										"map_script": `
												if (doc['ratings.items.nps'].size() > 0) {
													def nps = doc['ratings.items.nps'].value;
													if (nps >= 9) {
														state.promoters += 1;
													} else if (nps <= 6) {
														state.detractors += 1;
													}
													state.total += 1;
												}
											`,
										"combine_script": "return state;",
										"reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
												promoters += s.promoters;
												detractors += s.detractors;
												total += s.total;
											}
											
											double result = 0.0;
											if (total > 0) {
												result = ((promoters - detractors) / (double) total) * 100;
											}
											
											return (int) Math.ceil(result);
										`
									}
								},
								"total_ratings_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								},
								"total_shipments_count": {
									"value_count": {
										"field": "shipperGroup.name.keyword"
									}
								},
								"total_nps_count": {
									"value_count": {
										"field": "ratings.items.nps"
									}
								},
							}
						},
						"carrier": {
							"terms": {
								"field": "carrier.name.keyword",
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"rating_filter": {
									"bucket_selector": {
										"buckets_path": {
											"avgRating": "avg_rating"
										},
										"script": "params.avgRating != null"
									}
								}
							}
						}
					},
					sort: [
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],

					query: {
						bool: {
							must: [
								{
									"match": { status: "DELIVERED" }
								},
								{
									"exists": {
										"field": "ratings.items.feedback"
									}
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipperGroup) shipmentInit.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (shipper) shipmentInit.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (carrier) shipmentInit.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })

			let data = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);
			setRatings(data.hits.hits)
			setFacets(data.aggregations)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			setRatingCount(data?.hits?.total?.value);

			if (data?.aggregations?.avg_rating?.value) setRatingAverage(data?.aggregations?.avg_rating?.value);
			else setRatingAverage(0);

			if (data?.aggregations?.ratings_by_star?.buckets?.length) {
				let totaRatingCount = data?.aggregations?.ratings_by_star?.buckets?.reduce((accumulator, item) => accumulator + item?.rating_count?.value, 0,);
				setTotalStarRatingCount(totaRatingCount)
				setStarRating(data?.aggregations?.ratings_by_star?.buckets);
			}
			else { setStarRating([]); setTotalStarRatingCount(0) }


			if (data?.aggregations?.nps_stats) {
				let nps_stats = data?.aggregations?.nps_stats?.value
				let avg_nps = nps_stats?.npsScore ? Math.round(nps_stats?.npsScore) : 0
				let ratings = [
					{ label: 'Promoters', color: '#00A76F', percentage: nps_stats?.promotersPercentage, count: nps_stats?.promoters },
					{ label: 'Passives', color: '#FEC203', percentage: nps_stats?.passivesPercentage, count: nps_stats?.passives },
					{ label: 'Detractors', color: '#E84521', percentage: nps_stats?.detractorsPercentage, count: nps_stats?.detractors }
				]
				setNpsRating(ratings)
				setNpsAverage(avg_nps)
			} else {
				setNpsAverage(0);
				setNpsRating(intialNpsRating);
			}

			if (totalShipmentsCount) {
				let avg_response_rate = caluclateAverageResponseRate(totalShipmentsCount, data?.aggregations?.nps_stats?.value?.total, data?.aggregations?.total_ratings_count?.value)
				setResponseAverage(avg_response_rate)
			} else setResponseAverage(0)

			if (data?.aggregations?.shipper_group?.buckets?.length && feedbackCountData?.aggregations?.shipper_group?.buckets?.length) {
				for (let item of data?.aggregations?.shipper_group?.buckets) {
					let total_shipments_count = 0;
					for (let hit of feedbackCountData?.aggregations?.shipper_group?.buckets) {
						if (item.key === hit.key) {
							total_shipments_count += hit?.total_shipments_count?.value
						}
					}
					item.total_shipments_count = total_shipments_count;
					let avg_response_rate = caluclateAverageResponseRate(total_shipments_count, item.total_nps_count?.value, item.total_ratings_count?.value)
					item.avg_response_rate = avg_response_rate
				}
			}

			showSpinner(false);
		} catch (error) {
			showSpinner(false)
			handleApiError(error)
		}

	}

	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.subtract('days').startOf('day').unix(),
			toDate: moment().tz('America/Los_Angeles')?.endOf('day').unix()
		});
		setShipperGroup(null);
		setShipper(null);
		setKeyword('');
		setCarrier(null)
		setPageNumber(0);
		setTimeZone("America/New_York");
		setNpsRating(intialNpsRating);
		setResponseAverage(0);
		setNpsAverage(0)
		setFacets({})
		setTz("EST");
	};
	const tzHandle = (e) => {
		setTimeZone(e);
	};

	const calculatePercentage = (count) => count === 0 ? 0 : (count / ratingCount) * 100;

	return (
		<>
			<PageHeader name='StarPhox' shipperLabel={shipperGroup?.label}>
				<Row>
					<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					<ShipperFilter value={shipper} onChange={setShipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					{shipper && <CarrierFilterFromDeliveryService value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
					{!shipper && <CarrierFilter value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />}
					<DateFilter key={resetDate} onChange={setDateFilters} />

					<TimeZoneFilter

						title={"Time Zone"}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} startOf={6} styleName={"mt-4"} />
				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Average Rating' value={ratingAverage ? ratingAverage?.toFixed(2) : '-'} icon='star' />
					<StatCard title='NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
					<StatCard title='Response Rate' value={responseAverage ?? 0} icon='percent' />
				</Row>

				<Card>
					<Row className="gy-4" md={1} lg={2} >
						<Col className='p-5 border-end'>
							<div className="rating-summary">
								<div className="d-flex align-items-center mb-3">
									<div className='mb-2 me-2'>
										<StarRatings rating={ratingAverage || 0} starRatedColor='#FFCD3C' starDimension='18px' starSpacing='1px' />
									</div>
									<span>Based on {totalStarRatingCount} Reviews</span>
								</div>
								{starRating?.map((item) => (
									<div key={item?.key} className="d-flex align-items-center justify-content-between mb-2">

										<span className="me-2">{item?.key} <span className="text-warning">&#9733;</span></span>
										<div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
											<div
												className="progress-bar bg-warning"
												role="progressbar"
												style={{ width: `${calculatePercentage(item?.rating_count?.value || 0)}%` }}
												aria-valuenow={calculatePercentage(item?.rating_count?.value || 0)}
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
										<span>{item?.rating_count?.value}</span>
									</div>
								))}
							</div>
						</Col>
						<Col>
							{(npsRating[0]?.count || npsRating[1]?.count || npsRating[2]?.count) ?
								<Row md={1} lg={2} className='py-3 text-center'>
									<Col className="chart-container py-3" >
										<DoughnutChart
											backgroundColor={["#00A76F", "#FEC203", "#E84521"]}
											data={{
												labels: ['Promoters', 'Passives', 'Detractors'],
												datasets: [npsRating[0]?.count, npsRating[1]?.count, npsRating[2]?.count],
											}}
											height={200}
											hideLabel={true}
										/>
									</Col>
									<Col className="nps-summary d-flex flex-column justify-content-center mt-5">
										{npsRating?.map((item) => (
											<div key={item.label} className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2">
												<span
													className="me-2"
													style={{
														width: '12px',
														height: '12px',
														backgroundColor: item?.color,
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
												<span className="me-2">{item?.label}</span>
												<span className="fw-bold me-2">{item?.percentage?.toFixed(2)}%</span>
												<span className="text-muted">({item?.count})</span>
											</div>
										))}
									</Col>
								</Row> :
								<div className='d-flex align-items-center justify-content-center h-100 text-muted text-sm'>No NPS data found</div>
							}
						</Col>
					</Row>
				</Card>
				<Row>
					<Col md={12} lg={8}>
						<Card>
							<CardHeader>
								Ratings by Shipper Group
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-left'>Shipper Group</th>
											<th className='text-center'>Avg Rating</th>
											<th className='text-center'>Nps</th>
											<th className='text-center'>Avg Response Rate</th>
										</tr>
									</thead>
									<tbody>
										{facets && facets?.shipper_group?.buckets?.map((rating) => {
											return (
												<>
													< tr key={rating?.key}>
														<td className='text-left'>{rating.key}</td>
														<td className='text-center'>{rating?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</> : "-"}</td>
														<td className='text-center'>{rating?.avg_nps?.value}</td>
														<td className='text-center'>{rating?.avg_response_rate}</td>
													</tr >

												</>
											)
										})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
					<Col md={12} lg={4}>
						<Card>
							<CardHeader>
								Ratings by Courier
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-start'>#Rank</th>
											<th className='text-start'>Courier</th>
											<th className='text-center'>Average Star Rating</th>
										</tr>
									</thead>
									<tbody>
										{
											facets && facets?.carrier?.buckets?.map((rating, index) => {
												return (
													< tr key={rating?.key} >
														<td className='text-start'>#{index + 1}</td>
														<td className='text-start'>{rating?.key}</td>
														<td className='text-center'>{rating?.avg_rating?.value ? (<><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</>) : "-"}</td>
													</tr >
												)
											})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
				</Row>

				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Shipment #</th>
									<th style={{ wordWrap: "break-word", maxWidth: "200px" }}>Patient / Feedback</th>
									<th className='text-start'>Shipper</th>
									<th className='text-center'>Rating</th>
									<th className='text-center'>NPS</th>
									<th className='text-center'>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings && ratings.map((rating) => {
										return (
											<>
												{(rating?._source?.ratings?.items[0]?.feedback || rating?._source?.ratings?.items[0]?.nps || rating?._source?.ratings?.items[0]?.rating) &&
													< tr key={rating?._source?._source?.id} onClick={() => navigate(`/shipment/${rating?._source?.id}`)} style={{ cursor: 'pointer' }}>
														<td className='text-center'>{rating?._source?.number}</td>
														<td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
															{rating?._source?.ratings?.items[0]?.feedback || '-'}
															<div className='text-small text-muted text-wrap'>{rating?._source?.shipTo?.name}</div>
														</td>
														<td>
															{rating?._source.shipper?.name}
															<div className='small text-muted'>
																{rating?._source?.shipperGroup?.name}
															</div>
														</td>
														<td className='text-center tables-handle' style={{ width: '118px' }} >
															<StarRatings rating={rating?._source?.ratings?.items[0]?.rating || 0} starRatedColor='#FFCD3C' starDimension='14px' starSpacing='1px' />
														</td>
														<td className='text-center tables-handle d-flex align-items-center justify-content-center'>
															{rating?._source?.ratings?.items[0]?.nps ? <div className={`nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
														</td>
														<td className='text-center'>
															{toLocalTime(rating?._source?.createdTime, rating?._source?.shipper?.timezone?.id)}
															<div className='small text-muted'>{toLocalDate(rating?._source?.createdTime, rating?._source?.shipper?.timezone?.id)}</div>
														</td>
													</tr >
												}
											</>
										)
									})
								}
							</tbody>

							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container >
		</>
	);
};

export default RatingDashboard;
