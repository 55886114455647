import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import items from '../data/menu.json';
import Avatar from './Avatar';
import { useIdleTimer } from 'react-idle-timer';
import packageJson from "../../package.json"
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from '../helpers/remoteConfig';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import GreenPhoxIcon from './GreenPhoxIcon';
import { handleApiError } from '../helpers';




export const LeftMenu = () => {
    const location = useLocation();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [seconds, setSeconds] = useState(59);
    const [versionUpdateModal, showVersionUpdateModal] = useState(false)
    const [newVersion, setNewVersion] = useState();
    const [expandedItems, setExpandedItems] = useState(() => {
        const initialExpandedItems = {};
        items.forEach(item => {
            if (item.children) {
                initialExpandedItems[item.id] = true;
            }
        });
        return initialExpandedItems;
    });


    const handleToggle = (itemId) => {
        setExpandedItems((prevExpandedItems) => ({
            ...prevExpandedItems,
            [itemId]: !prevExpandedItems[itemId],
        }));
    };


    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                let { _value } = getValue(remoteConfig, "HQ_WEB_VERSION")
                setNewVersion(_value)
                if ((packageJson?.version && _value) && _value !== packageJson?.version) {
                    showVersionUpdateModal(true);
                }
            })
            .catch((err) => {
                handleApiError(err)
            });
    }, [])

    useEffect(() => {
        const interval = setTimeout(() => {
            if (showTimer && seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);


        return () => clearTimeout(interval);
    }, [seconds, showTimer]);

    const onIdle = () => {
        setShowTimer(true)
    }

    const onActive = () => {
        setShowTimer(false)
        setSeconds(59);
    }

    useIdleTimer({ onIdle, onActive, timeout: 840_000, throttle: 500 })

    return (
        <>
            {!isFullScreen && (
                <nav className='navbar navbar-vertical fixed-start navbar-expand-md navbar-light' id='sidebar'>
                    <div className='container-fluid'>
                        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
                            <span className='navbar-toggler-icon'></span>
                        </button>

                        <Link to='/dashboard' className='navbar-brand p-0'>
                            <div className='text-center'>
                                <img src='/img/logo.svg' alt='logo' className='img-fluid pb-1' style={{ width: '160px' }} />
                            </div>
                        </Link>

                        <hr className='navbar-divider my-3' />
                        <div className='collapse navbar-collapse d-flex justify-content-between' id='sidebarCollapse'>
                            <ul className='navbar-nav flex-column'>
                                {items.map(item => (
                                    <React.Fragment key={item?.id}>
                                        {item?.children ? (
                                            <li className='nav-item'>
                                                <a
                                                    className='nav-link collapsed'
                                                    href={`#${item?.id}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleToggle(item.id);
                                                    }}
                                                    data-bs-toggle='collapse'
                                                    role='button'
                                                    aria-expanded={expandedItems[item.id] || false}
                                                    aria-controls={item?.id}
                                                >
                                                    <>

                                                        <GreenPhoxIcon
                                                            isActive={item.id === 'green-phox'}
                                                            name={item.name}
                                                            icon={item.icon}
                                                        />
                                                    </>
                                                    <span className='toggle-arrow' style={{ marginLeft: '5px' }}>
                                                        {expandedItems[item.id]}
                                                    </span>
                                                </a>
                                                <div className={`collapse ${expandedItems[item.id] ? 'show' : ''}`} id={item?.id}>
                                                    <ul className='nav nav-sm flex-column'>
                                                        {item?.children.map((submenu) => (
                                                            <li className='nav-item' key={submenu.id}>

                                                                <Link to={submenu.path} className='nav-link'>
                                                                    <i className={`fe fe-${submenu.icon}`}></i>{submenu.name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ) : (
                                            <li className='nav-item' key={item?.id}>
                                                <Link to={item.path} className='nav-link' target={item?.target}>
                                                    <GreenPhoxIcon
                                                        isActive={item.id === 'green-phox'}
                                                        name={item.name}
                                                        icon={item.icon}
                                                    />
                                                </Link>
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <Link className='nav-link' target="_blank" to={"https://help.desk.phoxhealth.com/"}>
                                        <i className={`fe fe-life-buoy nav-item`}></i> Help Center
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link' target="_self" to={"/changelog"}>
                                        <i className={`fe fe-git-branch nav-item`}></i> {"Changelog"}<span className="badge bg-primary ms-auto">v{packageJson?.version || ""}</span>
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link' target="_self" to={"/profile"}>
                                        <i className={`fe fe-settings nav-item`}></i> My Profile
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link className='nav-link' target="_self" to={"/auth/logout"}>
                                        <i className={`fe fe-log-out nav-item`}></i> Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {
                            showTimer &&
                            <>
                                <div className='d-flex justify-content-center'>
                                    <h5 className='text-danger'>You will be logged out in</h5>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <h3 className='text-danger'>  <b> {seconds} s </b>  </h3>
                                </div>
                                <hr />
                            </>
                        }
                    </div>
                </nav>
            )}

            <Modal show={versionUpdateModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered  >
                <Modal.Body>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <i className='fe fe-alert-circle text-warning display-1'></i>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h1>Update Available  </h1>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h5 className='text-muted'>We have a new software update</h5>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <h5 className='text-muted'>Get the latest version {newVersion} </h5>
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <button className='btn btn-md btn-dark'
                                onClick={() => { window.location.reload(); showVersionUpdateModal(false) }}> Refresh Now </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}