import { API } from 'aws-amplify';
import "flatpickr/dist/flatpickr.css";
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormLabel, InputGroup, Modal } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { DateFilter } from '../../components/EntityFilter';
import Spinner from '../../components/Spinner';
import { TableClearFilter } from '../../components/TableFilter';
import { fileUpload, handleApiError, PAGE_SIZE, PAGE_TITLE } from '../../helpers';

const getList = `query ListTasks {
    listTasks(filter: {status: {eq: OPEN}}) {
        items {
        attachment
        comments
        createdTime
        description
        dueBy
        id
        label
        shipment {
            number
        }
        shipmentId
        status
        title
        user {
            name
            id
        }
        userId
        }
    }
    }`

const createTaskSub = `subscription createTaskSub {
        onCreateTask {
            attachment
            comments
            createdTime
            description
            dueBy
            id
            label
            shipmentId
            status
            title
            shipment {
            number
            }
            userId
            user {
            name
            id
            }
        }
            }`


const updateTaskSub = `subscription updateTaskSub {
                onUpdateTask {
                    attachment
                    comments
                    createdTime
                    description
                    dueBy
                    id
                    label
                    shipmentId
                    status
                    title
                    shipment {
                    number
                    }
                    userId
                    user {
                    name
                    id
                    }
                }
                    }`
const createTaskMutation = /* GraphQL */ `
            mutation CreateTask($input: CreateTaskInput!){
                createTask(input: $input) {
                id 
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;
const updateTaskMutation = /* GraphQL */ `
            mutation UpdateTask($input: UpdateTaskInput!){
                updateTask(input: $input) {
                id
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;

const shipmentIdByNumber = /* GraphQL */ `
            query MyQuery($number: Int!) {
            shipmentsByNumber(number: $number) {
                items {
                id
                }
            }}`;



export default function Task({ dark }) {
    const [taskList, setTaskList] = useState({});
    const [tz, setTz] = useState("EST");
    const [rawTaskList, setRawTaskList] = useState([])
    const myUser = useSelector(state => state.slice.USER)
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [keyword, setKeyword] = useState('')
    const [resetDate, setRestDate] = useState(0);
    const params = useParams()
    const [pageNumber, setPageNumber] = useState(0);
    const [dateFilters, setDateFilters] = useState(null);
    const textColor = dark ? 'text-light' : 'text-dark';
    const [isOnline, setIsOnline] = useState(navigator.onLine);




    const handleStatusChange = (onlineStatus) => {
        setIsOnline(onlineStatus);
        if (onlineStatus) {
            getTaskList();
        }
    };
    useEffect(() => {
        const updateOnlineStatus = () => {
            const onlineStatus = navigator.onLine;
            if (onlineStatus !== isOnline) {
                handleStatusChange(onlineStatus);
            }
        };


        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);


        updateOnlineStatus();

        return () => {

            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, [isOnline]);



    function handleNewTask(task) {
        task.tempId = uniqueId('task_');

        setRawTaskList((prevData) => {
            const updatedData = [{ ...task, comments: task.comments ? JSON.parse(task.comments) : [] }, ...prevData];
            const groupedData = Object.groupBy(updatedData, ({ label }) => label);
            setTaskList(groupedData);
            return updatedData;
        });
    }


    function handleUpdatedTask(updatedTask) {
        setRawTaskList((prevData) => {
            let data = [...prevData];
            if (updatedTask.status !== 'DONE') {
                const idx = data.findIndex((task) => task.id === updatedTask.id);
                if (idx !== -1) {
                    data.splice(idx, 1);
                }
                data.unshift({
                    ...updatedTask,
                    comments: updatedTask.comments ? JSON.parse(updatedTask.comments) : []
                });
            } else {
                data = data.filter((task) => task.id !== updatedTask.id);
            }

            const groupedData = Object.groupBy(data, ({ label }) => label);
            setTaskList(groupedData);
            return data;
        });
    }

    async function fetchTasks(pageNumber, PAGE_SIZE, keyword, dateFilters, selectedUser) {
        const apiName = 'api';
        const path = `/search/task?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
        let init = {
            body: {
                sort: [
                    { "_score": { "order": "desc" } },
                    { "updatedAt": { "order": "desc", "unmapped_type": "date" } }
                ],
                query: {
                    bool: {
                        must: [{ match: { 'status': 'OPEN' } }]
                    }
                }
            }
        };

        if (!keyword && dateFilters) {
            init.body.query.bool.must.push({
                range: {
                    "createdTime": { "gte": dateFilters?.fromDate, "lte": dateFilters?.toDate }
                }
            });
        }
        if (selectedUser?.value) {
            init.body.query.bool.must.push({ match: { "userId.keyword": selectedUser?.value } });
        }
        if (keyword) {
            let fields = ["label", "description", "title"];
            init.body.query.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "fields": fields
                }
            });
        }

        return await API.post(apiName, path, init);
    }

    async function getTaskList() {
        try {
            const res = await fetchTasks(pageNumber, PAGE_SIZE, keyword, dateFilters, selectedUser);
            const items = res.hits.hits;
            let data = items?.map((item) => item._source);
            setRawTaskList(data);

            const result = Object.groupBy(data, ({ label }) => label);
            setTaskList(result);
            setLoading(false);

            API.graphql({ query: createTaskSub }).subscribe({
                next: (response) => handleNewTask(response.value.data.onCreateTask),
                error: (error) => console.error('onCreateTask error', error),
            });

            API.graphql({ query: updateTaskSub }).subscribe({
                next: (response) => handleUpdatedTask(response.value.data.onUpdateTask),
                error: (error) => console.error('onUpdateTask error', error),
            });

        } catch (error) {
            handleApiError(error)
        }
    }



    const handleFullPage = () => {
        const elem = document.documentElement;

        if (!document.fullscreenElement) {
            elem.requestFullscreen().then(() => setIsFullScreen(true)).catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        }
    }

    useEffect(() => {
        const updateFullScreenStatus = () => {
            setIsFullScreen(document.fullscreenElement != null);
        };

        document.addEventListener('fullscreenchange', updateFullScreenStatus);

        return () => {
            document.removeEventListener('fullscreenchange', updateFullScreenStatus);
        };
    }, []);


    const searchUser = async (keyword) => {
        let items = [];
        try {
            const apiName = 'api';
            const path = `/search/user?size=100&from=0`;
            let init = {
                body: {
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                { match: { active: true } },
                                {
                                    terms: { "role.keyword": ['OWNER', 'EDITOR', 'VIEWER'] }
                                }
                            ]
                        }
                    }
                }
            };

            if (keyword) {
                delete init.body.sort;
                init.body.query.bool.must.push({
                    "multi_match": {
                        "query": keyword,
                        "fields": ["name"],
                        "fuzziness": "AUTO",
                        "minimum_should_match": "1<2",
                        "type": "best_fields"
                    }
                });
            }

            let data = await API.post(apiName, path, init);
            data.hits.hits.forEach(item => items.push({
                label: item?._source?.name,
                value: item?._source?.id
            }));
            return items

        } catch (error) {
            handleApiError(error)
        }
    }

    async function getIdByShipmentNumber(number) {
        try {
            let res = await API.graphql({ query: shipmentIdByNumber, variables: { number } })
            let shipment = res.data.shipmentsByNumber.items[0]
            return shipment?.id
        } catch (error) {
            handleApiError(error)
        }

    }



    const DEBOUNCE_DELAY = 300;
    useEffect(() => {
        const handler = setTimeout(() => {
            setLoading(true)
            getTaskList();
        }, DEBOUNCE_DELAY);
        return () => {
            clearTimeout(handler);
        };
    }, [keyword, dateFilters, selectedUser]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/task",
        })
        document.title = `Task ${PAGE_TITLE}`
    }, [])



    function Card({ task, setShowModal, setNewTask, setAttachment }) {

        useEffect(() => {

            if (params?.id) {
                let task = rawTaskList.find(item => item.id === params?.id);
                const comments = Array.isArray(task.comments) ? task.comments : JSON.parse(task.comments || "[]");
                const attachments = Array.isArray(task.attachment) ? task.attachment : [task.attachment];

                setAttachment(attachments);
                setNewTask({
                    ...task,
                    comments: comments,
                    attachment: attachments,
                    username: task.user?.name,
                    userId: task.userId,
                    shipmentNumber: task?.shipment?.number,
                    dueBy: task?.dueBy || moment().unix()
                });

                let div = document.getElementById(params?.id);
                div && div.click();
            }
        }, []);



        return (
            <div
                className='card mb-2 bg-light'
                id={task?.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    let all = task?.attachment?.map(item => {
                        return { url: item }
                    })

                    setAttachment(all)
                    setNewTask({ ...task, comments: task?.comments, username: task?.user?.name, userId: task?.userId, shipmentNumber: task?.shipment?.number, dueBy: task?.dueBy })
                    setShowModal(true)
                }}
            >
                <div className="p-3">
                    {task?.shipment?.number && <h5 className='text-muted'>#{task?.shipment?.number}</h5>}
                    <h4>{task?.title}</h4>
                    <div className="row align-items-center">
                        <div className="col">
                            <p className={`card-text small text-body-secondary ${moment().unix() > task?.dueBy && 'text-danger'}`}>
                                <i className="fe fe-clock" />{" "}
                                {moment.unix(task?.dueBy).format("MMM-DD hh:mm A")}
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="avatar-group bg-white rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                <h5 className='m-0'>{task?.user?.name?.split(" ")[0][0].toUpperCase()}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function Column({ title, tasks, state, setState, label }) {
        const navigate = useNavigate();
        const [showModal, setShowModal] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [isFileValid, setIsFileValid] = useState(true);
        const [attachment, setAttachment] = useState([]);
        const imgInputRef = useRef(null);
        const [isUploading, setIsUploading] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);
        const [newTask, setNewTask] = useState({ createdTime: moment().unix(), description: "", dueBy: null, label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })

        const [comment, setComment] = useState({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null });



        async function handleDrop(file) {
            if (!file.type.includes("image")) {
                toast.error("Invalid file type! Only image files are allowed.")
                setSelectedFile(null);
                return;
            }
            setIsUploading(true);
            let temp = structuredClone(attachment || []);
            temp.push({
                type: file?.type,
                url: await fileUpload(file),

            });
            setAttachment(temp);
            setIsUploading(false);
        }

        const handleRemove = (item) => {
            setAttachment((prev) => prev.filter((url) => url !== item));
        };



        async function handleTask(task) {
            try {
                for (const [key, value] of Object.entries(task)) {
                    if (typeof value === 'string' && /^\s+$/.test(value)) {
                        const formattedKey = key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, str => str.toUpperCase())
                            .trim();
                        return toast.error(`Invalid input: "${formattedKey}" should not contain spaces.`);
                    }
                }
                let shipmentId = null;
                setIsLoading(true)
                if (task.shipmentNumber) {
                    shipmentId = await getIdByShipmentNumber(task.shipmentNumber)
                    if (!shipmentId) {
                        setIsLoading(false)
                        return toast.error("No Shipment Found. Please check the Shipment Number entered !")
                    }
                }
                let rawIUrls = attachment?.map(item => item?.url)
                let comment = JSON.stringify(Array.isArray(task.comments) ? task.comments : [])
                let input = {
                    createdTime: moment().unix(), description: task.description, dueBy: task.dueBy || moment().unix(), label: task.label, shipmentId, status: 'OPEN', title: task.title, userId: task.userId,
                    comments: comment, attachment: rawIUrls
                }

                if (task?.id) {
                    delete input.createdTime
                    input.status = task.status
                    input.id = task.id
                }

                await API.graphql({ query: task?.id ? updateTaskMutation : createTaskMutation, variables: { input } })

                setShowModal(false)
                setNewTask({ createdTime: moment().unix(), description: "", dueBy: null, label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })

                toast.success(`Task ${task?.id ? "updated" : 'added'} successfully!`)
                navigate("/task");
            } catch (error) {
                console.error(error)
                setIsLoading(false)
                toast.error("Something went wrong !")
            }
        }


        return (


            <div className="bg-white rounded col col-3 p-0 border-">
                <div className={`p-3 rounded-top fw-bold`}>{title}</div>
                <div
                    style={{ overflowY: "auto", maxHeight: "60vh" }}
                    className="p-3"
                >
                    {tasks?.map((task, index) => (
                        <Card key={task.tempId || task.id} index={index} task={task} setShowModal={setShowModal} setNewTask={setNewTask} setAttachment={setAttachment} />
                    ))}

                </div>
                <Modal show={showModal} centered size='xl'>
                    <Modal.Body>
                        <h1>{newTask?.id ? 'Update' : 'Create a new'}  task</h1>
                        <hr />
                        <form className='row justify-content-between' onSubmit={(e) => {
                            e.preventDefault()
                            handleTask(newTask)
                        }}>
                            <div className='d-flex gap-2 flex-column col col-8'>
                                <Form.Group>
                                    <FormLabel>Title</FormLabel>
                                    <Form.Control required type='text' placeholder='Add a Title' defaultValue={newTask?.title} onChange={(e) => setNewTask((prev) => ({ ...prev, title: e.target.value }))} />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>Description</FormLabel>
                                    <textarea required className='form-control' type='textarea' rows={3} cols={30} placeholder='Add a Description' defaultValue={newTask?.description} onChange={(e) => setNewTask((prev) => ({ ...prev, description: e.target.value }))} />
                                </Form.Group>
                                <div className="mb-4">
                                    <div
                                        className="card"
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            handleDrop(e.dataTransfer.files[0], setAttachment);
                                        }}
                                    >
                                        <div className="card-body">
                                            <div
                                                className="dropzone dropzone-multiple"
                                                role="presentation"
                                                tabIndex="0"
                                            >
                                                <input
                                                    type="file"
                                                    hidden
                                                    ref={imgInputRef}
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        handleDrop(e.target.files[0], setAttachment)
                                                    }
                                                />
                                                <div className="dz-message">
                                                    Drop files or{" "}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm mx-2 btn-dark fw-bold"
                                                        onClick={() => imgInputRef.current.click()}
                                                        disabled={isUploading}
                                                    >
                                                        {isUploading ? "Uploading..." : "Upload"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Display Uploaded Files */}
                                        <div className="d-grid gap-1 mt-3 p-2"
                                            style={{
                                                gridTemplateColumns: 'repeat(auto-fill,minmax(50px,1fr))',
                                                gridTemplateColumns: 'repeat(10,1fr)',
                                                gap: "10px"
                                            }}
                                        >
                                            {Array.isArray(attachment) &&
                                                attachment.map((item, index) => (
                                                    <div key={index} className="position-relative" style={{ width: "fit-content", borderRadius: ".4rem" }}>
                                                        <img
                                                            src={item?.url || item}
                                                            alt="Uploaded preview"
                                                            height="40px"
                                                            width="30px"
                                                            style={{ borderRadius: ".4rem", objectFit: "cover" }}
                                                        />
                                                        <i
                                                            className="fe fe-x position-absolute top-0 end-0 bg-light"
                                                            style={{ cursor: "pointer", zIndex: 10 }}
                                                            onClick={() => handleRemove(item)}
                                                        ></i>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <FormLabel>Comments</FormLabel>
                                    <div className='row'>
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder='Add a comment...' value={comment?.message} onChange={(e) => setComment((prev) => ({ ...prev, message: e.target.value }))} />
                                            <div className="input-group-postpend">
                                                <button type='button' disabled={comment.message === ''} className='btn btn-dark ms-2' onClick={() => {
                                                    setComment({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null })
                                                    setNewTask((prev) => ({ ...prev, comments: [{ ...comment, createdAt: moment().unix() }, ...prev?.comments || []] }))
                                                }}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* comments */}
                                    <div className='mt-3'>
                                        {(newTask?.comments || []).map((item) => (
                                            <div key={item?.createdAt} className="comment mb-3">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="avatar-group bg-light rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                                            <h5 className='m-0'>{item?.username?.split(" ")[0][0]?.toUpperCase()}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col ms-n2">
                                                        <div className="comment-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h5 className="comment-title">{item?.username}</h5>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <time className="comment-time">{moment.unix(item?.createdAt).format("MMM DD hh:mm A")}</time>
                                                                </div>
                                                            </div>{" "}
                                                            <p className="comment-text">
                                                                {item?.message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='col col-4 border-left d-flex gap-2 flex-column' style={{ borderLeft: "2px solid  #e6e6e6" }}>
                                <Form.Group>
                                    <FormLabel>Status</FormLabel>
                                    <Form.Select disabled={!newTask?.id} className='phox-form-select' value={newTask?.status} onChange={(e) => setNewTask((prev) => ({ ...prev, status: e.target.value }))}>
                                        <option value='TODO'>OPEN</option>
                                        <option value='DONE'>DONE</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>User</FormLabel>
                                    <AsyncSelect cacheOptions defaultOptions
                                        placeholder='Select User'
                                        defaultValue={newTask?.userId ? { value: newTask?.userId, label: newTask?.username } : null}
                                        loadOptions={searchUser}
                                        onChange={(e) => setNewTask((prev) => ({ ...prev, userId: e.value, username: e.label }))}
                                    />
                                </Form.Group>
                                <div>
                                    <FormLabel>Label</FormLabel>
                                    <input type="text" required placeholder='Add a Label' className='form-control'
                                        value={newTask?.label} onChange={(e) => setNewTask((prev) => ({ ...prev, label: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Shipment</FormLabel>
                                    <input type="text" placeholder='Enter Shipment Number' className='form-control'
                                        value={newTask?.shipmentNumber} onChange={(e) => setNewTask((prev) => ({ ...prev, shipmentNumber: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Due By</FormLabel>
                                    <Flatpickr
                                        key={newTask?.dueBy || 'default'}
                                        className="form-control"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d h:i K",
                                            minDate: newTask?.dueBy ? new Date(newTask.dueBy * 1000).toISOString().slice(0, 10) : moment().unix()

                                        }}
                                        value={newTask?.dueBy ? new Date(newTask.dueBy * 1000) : new Date()}
                                        onChange={([selectedDate]) => {
                                            setNewTask((prev) => ({
                                                ...prev,
                                                dueBy: Math.floor(selectedDate.getTime() / 1000),
                                            }));
                                        }}
                                    />
                                </div>
                                <div className='d-flex justify-content-end mt-auto'>
                                    <button className='btn btn-light' type='button' onClick={() => {
                                        setShowModal(false)
                                        setAttachment([])
                                        setNewTask({ createdTime: moment().unix(), description: "", dueBy: moment().unix(), label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })
                                    }

                                    }>Cancel</button>
                                    <button disabled={!isFileValid || isLoading} className='btn btn-dark ms-1' type='submit'>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            newTask?.id ? "Update Task" : "+ Create Task"
                                        )}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <div className="d-flex justify-content-center">
                    <button onClick={() => {
                        setShowModal(true)
                        setAttachment([])
                        setNewTask({ createdTime: moment().unix(), description: "", dueBy: null, label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })
                    }
                    } className='m-2 btn btn-sm btn-dark'>+ Add Task</button>
                </div>
            </div>

        )
    }
    const clearFilters = () => {
        setDateFilters(null);
        setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setLoading(true)
        getTaskList();
        setSelectedUser(null);

    }
    const tzHandle = (e) => {
        setTimeZone(e);
    };
    return (
        <div>
            <div name="Tasks Board" className="d-flex justify-content-between align-items-center p-3">

                <div
                    className="d-flex justify-content-between align-items-center w-100 p-3 border-bottom m-4"
                    style={{
                        borderBottom: '1px solid #e3ebf6',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <h1 className='header-title me-2'>Tasks Board</h1>
                        <span>{isOnline ? '🟢 Online' : '🔴 Offline'}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Link to="/tasklist">
                            <Button className='bg-dark border border-0'>
                                Task List
                            </Button>
                        </Link>
                        <div className="fullPage cursor-pointer" onClick={handleFullPage}>
                            {isFullScreen ? (
                                <i className='fe fe-minimize' />
                            ) : (
                                <i className='fe fe-maximize' />
                            )}
                        </div>
                    </div>
                </div>



            </div>

            <div className="d-flex align-items-center justify-content-between py-1 px-5">

                <InputGroup style={{ width: 'auto', marginRight: '1rem' }}>
                    <Form.Control
                        placeholder="Search here..."
                        aria-label="Search list"
                        onChange={(e) => setKeyword(e.target.value.toLowerCase())}
                        style={{ height: '38px' }}
                    />
                </InputGroup>


                <div className="d-flex align-items-center gap-3 px-1 my-1">
                    <div style={{ minWidth: '150px' }}>
                        <div className={`small ${textColor}`}>Users</div>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    fontSize: '0.75rem',
                                    backgroundColor: '#EDF2F9',
                                    borderWidth: '0px',
                                    minHeight: '28px'
                                }),

                                dropdownIndicator: (baseStyles) => ({
                                    ...baseStyles,
                                    padding: '2px',
                                }),
                                clearIndicator: (baseStyles) => ({
                                    ...baseStyles,
                                    padding: '2px',
                                }),
                            }}
                            placeholder="All"
                            value={selectedUser ? { value: selectedUser?.value, label: selectedUser?.label } : null}
                            loadOptions={searchUser}
                            onChange={(e) => setSelectedUser(e)}
                        />
                    </div>
                    <DateFilter
                        key={resetDate}
                        onChange={setDateFilters}

                    />
                    <TableClearFilter
                        onClick={clearFilters}
                        styleName="mt-4"
                    />
                </div>
            </div>


            <Spinner display={loading}>
                <div className='px-5 d-flex gap-3 align-items-start' style={{ overflow: 'auto' }}>
                    {Object.keys(taskList).map((item, idx) => (
                        <Column key={idx} title={item} tasks={taskList[item]} id={idx} />
                    ))}
                    <Column title={''} tasks={[]} id={'new'} />
                </div>
            </Spinner>
        </div>
    )
}

