import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Dropdown, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableFacetFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, handleApiError, PAGE_SIZE } from '../../helpers';
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import ReactGA from "react-ga4"
import { TableClearFilter } from '../../components/TableFilter';

const updateCustomerMutation = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
	  name
	  active
    }
  }
`;

const PatientsList = () => {

    const myUser = useSelector((state) => state.slice.USER)

    const navigate = useNavigate()
    const [spinner, showSpinner] = useState(false);
    const [active, setActive] = useState('');
    const [customers, setCustomers] = useState([])
    const [keyword, setKeyword] = useState('')
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [facets, setFacets] = useState({})
    const [shipper, setShipper] = useState();
    const [shipperGroup, setShipperGroup] = useState();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/patients",
        })
    }, [])

    useEffect(() => {
        setShipper();
    }, [shipperGroup])

    useEffect(() => {
        getElasticCustomer()
    }, [myUser, pageNumber, active]);

    useEffect(() => {
        const delay = setTimeout(() => { setPageNumber(0); getElasticCustomer(); }, DELAY_TIMEOUT)
        return () => clearTimeout(delay)
    }, [keyword, shipper, shipperGroup])

    const getElasticCustomer = async () => {
        showSpinner(true);
        try {
            const apiName = 'api';
            const path = `/search/customer?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;

            let init = {}
            if (!keyword && !shipper && !shipperGroup && active === '') {
                init = {
                    body: {
                        aggs: {
                            shipper_group: {
                                terms: {
                                    "field": "shipperGroup.name.keyword",
                                    "size": 1000
                                }
                            },
                            shipper: {
                                terms: {
                                    "field": "shipper.name.keyword",
                                    "size": 1000
                                }
                            },
                        },
                        sort: [{ "updatedAt": { "order": "desc", "unmapped_type": "date" } }],
                        match: {
                            _index: "customer"
                        }
                    }
                }

            } else {
                init = {
                    body: {
                        aggs: {
                            shipper_group: {
                                terms: {
                                    "field": "shipperGroup.name.keyword",
                                    "size": 1000
                                }
                            },
                            shipper: {
                                terms: {
                                    "field": "shipper.name.keyword",
                                    "size": 1000
                                }
                            }
                        },
                        query: {
                            bool: {
                                should: [],
                                must: []
                            }
                        }

                    }
                };

                if (keyword) {
                    // while searching with keyword we dont want to sort
                    delete init.body.sort
                    init.body.query.bool.must.push(
                        {
                            "multi_match": {
                                "query": keyword,
                                "fields": [
                                    "name^3",
                                    "first_name^2",
                                    "last_name^2",
                                    "email",
                                    "extId"
                                ]
                            }
                        }
                    )
                }

                if (shipper) init.body.query.bool.must.push({ match: { "shipper.name.keyword": shipper } })
                if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroup.name.keyword": shipperGroup } })
                if (active === true) init.body.query.bool.must.push({ match: { active: active } })
                else if (active === false) init.body.query.bool.must.push({ match: { active: active } })
            }
            let data = await API.post(apiName, path, init);
            setFacets(data.aggregations)
            setCustomers(data.hits.hits);
            setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
            showSpinner(false);

        } catch (error) {
            showSpinner(false);
            handleApiError(error)
        }

    }

    const updateCustomer = (customer, status) => {
        showSpinner(true)
        API.graphql({ query: updateCustomerMutation, variables: { input: { id: customer?._source?.id, active: status } } })
            .then(async (response) => {
                setTimeout(() => {
                    getElasticCustomer()
                }, 1000)
            }).catch((error) => {
                handleApiError(error)
                showSpinner(false)
            })
    }
    const clearFilters = () => {
        setKeyword('');
        setShipperGroup(null);
        setShipper(null);
        setActive('');
    }
    return (
        <>
            <PageHeader name='Patients' shipperLabel={shipperGroup}>
                {myUser?.role !== 'VIEWER' && <Link to='/patient/create' className='btn btn-dark'>New Patient</Link>}
                <Button className='btn btn-light ms-2' onClick={() => window.location.reload()}>
                    <i className='fe fe-refresh-cw'></i>
                </Button>
            </PageHeader>

            <Container fluid>
                <Card>
                    <Card.Header>
                        <Row>
                            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
                            <ElasticFacetFilter name='Shipper Group' item={shipperGroup} facets={facets} facetName='shipper_group' onChange={setShipperGroup} />
                            <ElasticFacetFilter name='Shipper' item={shipper} facets={facets} facetName='shipper' onChange={setShipper} />
                            <PatientActiveFilter active={active} onChange={setActive} />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </Card.Header>
                    <Spinner display={spinner}>
                        <Table responsive size='sm' className='mb-0' >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Active</th>
                                </tr>
                            </thead>
                            <tbody>

                                <Spinner display={spinner}>
                                    {
                                        customers.map((customer) => {
                                            return (
                                                <tr key={customer?._source?.id} className={myUser?.role !== 'VIEWER' && 'cursor-pointer'} onClick={() => myUser?.role !== 'VIEWER' && navigate(`/patient/edit/${customer?._source?.id}`)}>
                                                    <td>
                                                        {customer?._source?.name}
                                                        <div className='text-small text-muted'>{customer?._source?.alias}</div>
                                                    </td>
                                                    <td className='text-wrap'>
                                                        {customer?._source?.address?.address1}
                                                        <div className='small text-muted'>{customer?._source?.address?.city}, {customer?._source?.address?.state} {customer?._source?.address?.postalCode}</div>
                                                    </td>
                                                    <td className='text-wrap'>{customer?._source?.email}</td>
                                                    <td className='text-wrap'>{customer?._source?.phone}</td>
                                                    <td className='text-wrap' onClick={(e) => e.stopPropagation()} >
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant='light' size='sm'>{(customer?._source?.active === true ? 'Active' : 'Inactive')}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => { updateCustomer(customer, true) }}>Active</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => { updateCustomer(customer, false) }}>Inactive</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Spinner>
                            </tbody>
                            <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={5} />
                        </Table>
                    </Spinner>
                </Card>
            </Container>
        </>
    );
};

export default PatientsList;
